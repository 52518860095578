<template>
  <div id="students" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <p class="title is-4 has-text-right">
        {{ schoolClassName }}
      </p>
      <b-table :data="studentsInClass" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          studentsInClass.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="Avatar" v-slot="props">
          <figure class="image is-48x48">
            <img
              class="is-rounded"
              :src="props.row.avatar"
              alt="avatar"
              v-if="props.row.avatar"
            />
            <img
              class="is-rounded"
              src="@/assets/images/default_avatar.png"
              alt="avatar"
              v-else
            />
          </figure>
        </b-table-column>

        <b-table-column label="RegId" v-slot="props">{{
          props.row.regId.toUpperCase()
        }}</b-table-column>

        <b-table-column label="Name" v-slot="props">{{
          props.row.name
        }}</b-table-column>

        <b-table-column label="Gender" v-slot="props">{{
          props.row.gender
        }}</b-table-column>

        <b-table-column label="Parent" v-slot="props">
          <figure class="image is-24x24">
            <img
              src="@/assets/images/square-green.png"
              alt="avatar"
              v-if="props.row.parentId"
            />
            <img src="@/assets/images/square-red.png" alt="avatar" v-else />
          </figure>
        </b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            @initialize-update="initializeUpdate(props.row)"
            @initialize-remove="initializeRemove(props.row)"
            :show-update="user.role === 'host' || user.role === 'admin'"
            :show-remove="user.role === 'host' || user.role === 'admin'"
          />
        </b-table-column>
      </b-table>
    </template>
    <!-- Delete -->
    <app-modal
      id="remove-student"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'
import { fetchUser } from '@/assets/js/app_info.js'

export default {
  name: 'students',
  data() {
    return {
      pageTitle: 'Students',
      user: {},
      isActive: false,
      loading: false,
      studentsInClass: [],
      schoolClassName: '',
      schoolClassId: null,
      id: null,
    }
  },
  watch: {
    studentsInClass(data) {
      if (data.length == 0) return
      this.schoolClassName = data[0].schoolClass.name
    },
  },
  methods: {
    initializeInfo(student) {
      this.$router.push(
        `/school/${this.schoolId}/school_class/${this.$route.params.school_class_id}/student_info/${student.id}`
      )
    },
    initializeUpdate(student) {
      this.$router.push(`/school/${this.schoolId}/student/${student.id}`)
    },
    initializeRemove(student) {
      this.id = parseInt(student.id)
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteStudent($id: Int!) {
              deleteStudent(input: { id: $id }) {
                student {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteStudent.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.studentsInClass.refetch()
          })
        })
        .catch((error) => {
          // console.error(error);
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)

    this.$store.commit('setPageTitle', this.pageTitle)

    fetchUser().then((user) => {
      this.user = user

      if (user.role === 'host' || user.role === 'admin') {
        this.$store.commit('setSubMenus', [
          {
            name: 'Students',
            route: `/school/${this.schoolId}/students_in_school`,
          },
        ])
      } else if (user.role === 'educator') {
        this.$store.commit('setSubMenus', [
          {
            name: 'Assigned Classes',
            route: `/school/${this.schoolId}/educator_assigned_classes`,
          },
        ])
      }
    })

    this.$apollo.addSmartQuery('studentsInClass', {
      query() {
        if (this.$route.params.school_class_id) {
          return gql`
            query StudentsInClassQuery($schoolClassId: Int!) {
              studentsInClass(schoolClassId: $schoolClassId) {
                id
                imageUrl
                regId
                name
                gender
                parentId
                ownerId
                schoolClass {
                  name
                }
              }
            }
          `
        }
      },
      variables: {
        schoolClassId: parseInt(this.$route.params.school_class_id),
      },
      fetchPolicy: 'no-cache',
    })
    this.$apollo.queries.studentsInClass.refetch()
  },
}
</script>
